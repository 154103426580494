import React, { useContext, useState, useEffect } from 'react'
import { TableBody, Table, TableContainer, CircularProgress, TableCell, Paper, TableHead, TableRow } from '@material-ui/core'
import Context from 'reactContext'
import Main from 'components/main'
import { get } from 'utils/request'
import { formattedTime } from 'utils'

const Payments = () => {
  const [loading, setLoading] = useState(true)
  const { payments, paymentsSet } = useContext(Context)
  const rowData = Object.values(payments.toJS())
    .filter((v) => v.state === 'succeeded')
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))

  const paymentsIndex = async () => {
    const res = await get('managers/payments')
    if (res.ok) {
      paymentsSet(res.payments)
      setLoading(false)
    }
  }

  useEffect(() => {
    paymentsIndex()
  }, [])

  return (
    <Main>
      <div className="text-center">
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>创建时间</TableCell>
                  <TableCell>订单主题</TableCell>
                  <TableCell>订单金额</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((v) => (
                  <TableRow key={v.created_at}>
                    <TableCell align="left">{formattedTime(v.created_at)}</TableCell>
                    <TableCell align="left">{v.subject}</TableCell>
                    <TableCell align="left">{`¥ ${v.amount / 100}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Main>
  )
}

export default Payments
